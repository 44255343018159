const units = [
  { unit: 'byte', exp: 0 },
  { unit: 'KB', exp: 3 },
  { unit: 'MB', exp: 6 },
  { unit: 'GB', exp: 9 },
  { unit: 'TB', exp: 12 },
  { unit: 'PB', exp: 15 },
];

// Converts an integer number of bytes to a multiple, e.g.: 250000000 => 250 MB
export const BytesPrettify = (bytes) => {
  // Make sure variable is a number
  const size = Number(bytes);
  // Create an Object with value 0 byte by default
  const pretty = { size: 0, unit: units[0].unit, string: `0 ${units[0].unit}` };

  // Iterate each unit
  units.forEach((unit) => {
    // Get the value of the Unit
    const q = size / (10 ** unit.exp);

    // Check if the quocient is between 1 and 1000, then set the Object to return
    if (q >= 1 && q < 1000) {
      pretty.size = q === Math.floor(q) ? q : q.toPrecision(3);
      pretty.unit = unit.unit;
      pretty.string = `${`${q}`.substring(0, 5)} ${unit.unit}`;
    }
  });

  return pretty;
};

export const KBytesPrettify = (kBytes) => (BytesPrettify(kBytes * 1000));
